import React from "react";
import {useRouteMatch} from "react-router-dom";
import i18n from "i18next";
import {App} from "./app";
import {Language} from "../index";

interface AppParams {
    language: string;
    pagetype: string;
}

function LocalizedApp() {
    const match = useRouteMatch<AppParams>("/:language/:pagetype")
    let language: Language;
    if (match) {
        i18n.changeLanguage(match.params.language);
        language = match.params.language as Language;
    } else {
        language = 'en';
    }
    return (
        <App language={language}/>
    );
}

export default LocalizedApp;
