import React from "react";
import {useRouteMatch} from "react-router-dom";
import {Trans} from "react-i18next";

interface UrlMatch {
    language: string;
    pageType: string
}

export default function PageType() {
    const match = useRouteMatch<UrlMatch>("/:language/:pageType")
    let pageType = match?.params.pageType;
    let content = "Unknown";
    if (pageType === "home") {
        content = "Home"
    } else if (pageType === "posts") {
        content = "Blog"
    } else if (pageType === "contact") {
        content = "Contact"
    } else if (pageType === "cv") {
        content = "Curriculum Vitae"
    }
    return <div id="pageType"><Trans>{content}</Trans></div>
}
