import {Trans} from "react-i18next";
import React from "react";

class Home extends React.Component {
    render() {
        return (
            <div className={'col-md-12'}>
                <Trans i18nKey={'homeText'}/>
            </div>
        );
    }
}

export default Home;
