import React from "react";
import './name.scss';

class Name extends React.Component<{ active: boolean }, any> {
    render() {
        return (
            <div id={"topname"} className={`${this.props.active ? "active" : ""}`}>
                <span id="first">Joachim</span>
                <span id="dot">.</span>
                <span id="last">Nielandt</span>
            </div>
        );
    }
}

export default Name;
