import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Home from "./home";
import Blog from "./blog";
import Cv from "./cv";
import Contact from "./contact";
import {Language} from "../index";


class Page extends React.Component<{ language: Language }, { pageType: string | null}> {
    constructor(props: { language: Language }) {
        super(props);
        this.state = {
            pageType: null
        };
    }

    render() {
        return (
            <Switch>
                <Redirect exact from="/" to="/en/home"><Home/></Redirect>
                <Route path="/:language/home"><Home/></Route>
                <Route path="/:language/posts"> <Blog language={this.props.language}/> </Route>
                <Route path="/:language/contact"> <Contact/> </Route>
                <Route path="/:language/cv"> <Cv/> </Route>
            </Switch>
        );
    }
}

export default Page;
