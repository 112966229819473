import {Trans} from "react-i18next";

function Experience(props: {
    to: string | null, icon: string, where: string, from: string, role: string, description: any
}) {
    return (
        <div className={'cvitem'}>
            <div className={'cv-icon'}>
                <img src={"/assets/images/" + props.icon} alt={`icon for ${props.where}`}/>
            </div>
            <div className={'cv-where'}><Trans>{props.where}</Trans></div>
            <div className={'cv-role'}><Trans>{props.role}</Trans></div>
            <div className={'cv-when'}>{props.from} - {props.to}</div>
            <div className={'cv-description'}>{props.description}</div>
        </div>
    )
}

function Diploma(props: {
    to: string | null, icon: string, where: string, from: string, diploma: any, description: any
}) {
    return (
        <div className={'cvitem'}>
            <div className={'cv-icon'}>
                <img alt={`icon for school ${props.where}`} src={"/assets/images/" + props.icon}/>
            </div>
            <div className={'cv-where'}><Trans>{props.where}</Trans></div>
            <div className={'cv-diploma'}>{props.diploma}</div>
            <div className={'cv-when'}>{props.from} - {props.to}</div>
            <div className={'cv-description'}><Trans>{props.description}</Trans></div>

        </div>
    )
}

function Cv() {
    return (<div id={'cv'}>
        <div id={'cv-header'}><Trans>Experience</Trans></div>
        <div className={'cvitems'}>
            <Experience icon={'vives-icon.png'} where={'VIVES University of Applied Sciences'} from={'2020'} to={null}
                        role={'Lector / researcher'} description={null}/>
            <Experience icon={'imec-icon.png'} where={'imec / Ghent University'} from={'2018'} to={'2020'}
                        role={'Postdoctoral associate'} description={null}/>
            <Experience icon={'ugent-icon.png'} where={'Ghent University'} from={'2017'} to={'2018'}
                        role={'Postdoctoral researcher'} description={null}/>
            <Experience icon={'ugent-icon.png'} where={'Ghent University'} from={'2010'} to={'2017'}
                        role={'PhD student / assistent'} description={null}/>
            <Experience icon={'oxynade-icon.png'} where={'Oxynade'} from={'2009'} to={'2010'}
                        role={'Software developer'} description={null}/>
        </div>
        <div id={'cv-header'}><Trans>Education</Trans></div>
        <div id={'cvitems'}>
            <Diploma icon={'ugent-icon.png'} where={'Ghent University'} from={'2010'} to={'2017'}
                     diploma={<Trans i18nKey={'titleDoctor'}/>}
                     description={<Trans i18nKey={'manuscript'}/>}/>
            <Diploma icon={'ugent-icon.png'} where={'Ghent University'} from={'2003'} to={'2010'}
                     diploma={<Trans i18nKey={'titleMaster'}/>}
                     description={null}/>
            <Diploma icon={'pestalozzi-icon.png'} where={'Leonardo Lyceum/Pestalozzi'} from={'1999'} to={'2003'}
                     diploma={<Trans>Latin + Sciences / Math</Trans>} description={null}/>
            <Diploma icon={'zotvana.jpg'} where={'Leonardo Lyceum Esemnegen'} from={'1999'} to={'1997'}
                     diploma={<Trans>Latin</Trans>} description={null}/>
        </div>
    </div>)
}

export default Cv;
