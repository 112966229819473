import React from "react";
import BlogTag, {TagType} from "./blogTag";

interface TagFilterProps {
    type: TagType;
    toggleActive: (tag: TagType) => void;
    active: boolean;
}

function TagFilter(props: TagFilterProps) {
    return (
        <span title={'Filter: '+props.type} className={`tagFilter ${props.active ? 'active' : ''}`} onClick={() => props.toggleActive(props.type)}>
            <BlogTag type={props.type}/>
        </span>
    );
}

export default TagFilter;
