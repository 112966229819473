import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            homeText: "These pages are a mish-mash of things that I'd like to keep safe. Descriptions and photos of projects, so I don't forget about them. Blog-style recollections of pieces of code, so I don't forget about them. Consider it a notepad of some kind. ",
            "Home": "Home",
            "nl-full": "Dutch",
            'en-full': "English",
            titleMaster: 'Master, computer sciences - software engineering, cum laude',
            titleDoctor: 'Doctor of Computer Science Engineering',
            manuscript: "Manuscript: \"XPath-based information extraction\" - extraction of information from semi-structured documents, such as HTML, using XPath"
        }
    },
    nl: {
        translation: {
            homeText: "Deze web pagina's zijn een samenraapsel van verschillende dingen: notities over technische oplossingen die ik niet opnieuw wil opzoeken, foto's en beschrijvingen van projecten die ik gedaan heb en nog zou willen doen, curriculum vitae ... Kortom, alles wat je op een typische social media website zou vinden, maar dan in eigen beheer. Het is ineens ook een excuus om wat met web technologie te prutsen, dat is altijd meegenomen. ",
            "Home": "Welkom",
            "nl-full": "Nederlands",
            "en-full": "Engels",
            "Ghent University": "Universiteit Gent",
            "imec / Ghent University": "imec / Universiteit Gent",
            "VIVES University of Applied Sciences": "Hogeschool VIVES",
            "Postdoctoral researcher": "Postdoctoraal onderzoeker",
            "Postdoctoral associate": "Postdoctorale medewerker",
            "Software developer": "Softwareontwikkelaar",
            "Work address": "Adres werk",
            "Platforms": "Platformen",
            "Street": "Straat",
            "Postal code": "Postcode",
            "City": "Plaats",
            "Affiliation": "Affiliatie",
            "Experience": "Werkervaring",
            "Education": "Opleiding",
            "Lector / researcher": "Lector / onderzoeker",
            "Latin": "Latijn",
            "Latin + Sciences / Math": "Latijn + Wetenschappen / Wiskunde",
            titleMaster: 'Master computerwetenschappen, Software ontwikkeling, cum laude',
            titleDoctor: "Doctor in de ingenieurswetenschappen: computerwetenschappen",
            manuscript: "Manuscript: \"XPath-gebaseerde informatie-extractie\" - de extra van informatie uit semi-gestructureerde documenten, zoals HTML, met XPath"
        }
    }
};

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    // .use(Backend)
    // // detect user language
    // // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: resources,
        lng: 'nl',
        // fallbackLng: 'en',
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
