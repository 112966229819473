import {faAddressCard, faHeartbeat, faHome, faLanguage, faStickyNote} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Button from "./button";
import {Trans} from "react-i18next";
import {Language} from "../index";

interface ButtonsProps {
    mousing: (title: string | null) => void,
    language: Language
}

interface ButtonsState {
    highlightedTitle: string | null
}

class Buttons extends React.Component<ButtonsProps, ButtonsState> {

    constructor(props: ButtonsProps) {
        super(props);
        this.state = {
            highlightedTitle: null
        }
    }

    titleToText(title: string | null, language: Language): string | null {
        if (title === 'cv') {
            return 'Curriculum Vitae';
        } else if (title === 'contact') {
            return 'Contact';
        } else if (title === 'home') {
            return 'Home';
        } else if (title === 'posts') {
            return 'Blog';
        } else if (title === 'language') {
            return language + '-full';
        } else {
            return null;
        }
    }

    getHighlightedLink = (title: string | null) => {
        // fire callback for the link change
        this.props.mousing(title);
        // update our state
        this.setState({highlightedTitle: title})
    }

    render() {
        return (
            <div id={'button-container'}>
                <div id={"highlightedlink"}>
                    <Trans>{this.titleToText(this.state.highlightedTitle, this.props.language)}</Trans>
                </div>
                <div id="top-buttons">
                    <Button icon={faHome} id={'home'} mousing={this.getHighlightedLink} language={this.props.language}/>
                    <Button icon={faStickyNote} id={'posts'} mousing={this.getHighlightedLink}
                            language={this.props.language}/>
                    <Button icon={faHeartbeat} id={'cv'} mousing={this.getHighlightedLink}
                            language={this.props.language}/>
                    <Button icon={faAddressCard} id={'contact'} mousing={this.getHighlightedLink}
                            language={this.props.language}/>
                    <Button icon={faLanguage} id={'language'} mousing={this.getHighlightedLink}
                            language={this.props.language}/>
                </div>
            </div>
        );
    }
}

export default Buttons;
