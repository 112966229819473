import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {Language} from "../index";

interface ButtonProps {
    id: string,
    language: Language,
    icon: IconDefinition,
    mousing: (title: string | null, language: Language) => void
}

function Button(props: ButtonProps) {
    const location = useLocation();
    // figure out new location when clicking 'language'
    const languages = ['en', 'nl'];
    const matches = location.pathname.match(/\/([a-z]{2})(\/.*)/);
    let newLink = '';
    let newLanguage = 'en';
    if (matches) {
        newLanguage = languages[(languages.indexOf(matches[1]) + 1) % languages.length];
        newLink = `/${newLanguage}${matches[2]}`
    }

    return (
        <div
            className={"top-button"}
            onMouseEnter={(e) => {
                props.mousing(props.id, props.language)
            }}
            onMouseLeave={(e) => {
                props.mousing(null, props.language)
            }}>
            {
                (props.id === 'language') &&
                <Link to={newLink}>
                    <FontAwesomeIcon size='2x' icon={props.icon} fixedWidth/>
                    {/*<i className="fas fa-2x fa-fw fa-language"></i>*/}
                </Link>
            }
            {
                (props.id !== 'language') &&
                <Link to={`/${props.language}/${props.id}`}>
                    <FontAwesomeIcon size='2x' icon={props.icon} fixedWidth/>
                </Link>
            }
        </div>
    )
}

export default Button;
