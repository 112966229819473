import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import {faDeviantart, faFlickr, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {Trans} from "react-i18next";

export default class Contact extends React.Component {
    render() {
        return (
            <div id={'contact'}>
                <div className={'contact-header'}>
                    <Trans>Work address</Trans>
                </div>
                <div id={"work-address"} className={'contact-block'}>
                    <div className={'row'}>
                        <div className={'col-md-6 text-right'}><Trans>Affiliation</Trans></div>
                        <div className={'col-md-6'}>VIVES Campus Kortrijk</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-6 text-right'}><Trans>Street</Trans></div>
                        <div className={'col-md-6'}>Doorniksesteenweg 145</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-6 text-right'}><Trans>City</Trans></div>
                        <div className={'col-md-6'}>Kortrijk</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-6 text-right'}><Trans>Postal code</Trans></div>
                        <div className={'col-md-6'}>8500</div>
                    </div>
                </div>
                <div className={'contact-header'}>
                    <Trans>Platforms</Trans>
                </div>
                <div id={'platforms'} className={'contact-block'}>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/joachim-nielandt-5a01ba12/"
                       title="LinkedIn">
                        <FontAwesomeIcon color={'#0084bf'} icon={faLinkedin} size={'2x'}/>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.flickr.com/photos/countzukula/"
                       title="Flickr">
                        <FontAwesomeIcon color={'#ff0084'} icon={faFlickr} size={'2x'}/>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://tsuketoe.deviantart.com/" title="Deviantart">
                        <FontAwesomeIcon color={'#05cc47'} icon={faDeviantart} size={'2x'}/>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.icheckmovies.com/profiles/countzukula/"
                       title="iCheckMovies">
                        <FontAwesomeIcon color={'#000'} icon={faCheckSquare} size={'2x'}/>
                    </a>
                </div>
            </div>
        );
    }
}
