/**
 * Single item in a blog. Can be used in a list.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
import React from "react";
import BlogTag, {TagType} from "./blogTag";
import {Language} from "../index";

interface BlogItemProps {
    language: Language,
    title: string,
    date: Date,
    id: string,
    tags: Array<string>
}

interface BlogItemState {
    content: string,
    title: string,
    date: Date,
    tags: Array<string>
}

class BlogItem extends React.Component<BlogItemProps, BlogItemState> {
    constructor(props: any) {
        super(props);
        this.state = {
            content: '',
            title: this.props.title,
            date: this.props.date,
            tags: this.props.tags
        };
    }

    componentDidMount() {
        // always load posts at mount
        this.refreshPosts()
    }

    componentDidUpdate(prevProps: Readonly<{ language: Language; title: string; date: Date; id: string; tags: Array<string> }>, prevState: Readonly<{ content: string; title: string; date: Date; tags: Array<string> }>, snapshot?: any) {
        // only refresh if the language changed
        if(prevProps.language!==this.props.language) {
            this.refreshPosts()
        }
    }

    refreshPosts() {
        let url = `.././assets/post/${this.props.id}/full-${this.props.language}.html`;
        fetch(url)
            .then(result => result.text(), reason => {
                console.log('could not fetch...' + url);
            })
            .then((value) => {
                if (value) {
                    this.setState({
                        content: value
                    });
                }
            });
    }

    render() {
        return (
            <div className={'post'}>
                <h1 className={'post-title'}>{this.state.title}</h1>
                <div className={'post-date'}>{this.state.date.toLocaleDateString()}</div>
                <div className={'post-tags'}>
                    {this.state.tags.map(tag => <BlogTag type={tag as TagType} key={`${this.props.id}-${tag}`}/>)}
                </div>
                <div className="post-content" dangerouslySetInnerHTML={{__html: this.state.content}}/>
            </div>
        );
    }
}

export default BlogItem;
