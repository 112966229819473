import React from "react";
import {TagType} from "./blogTag";
import TagFilter from "./tagFilter";

interface TagFiltersProps {
    activeTagsChanged: (tags: Array<TagType>) => void;
}

class TagFilters extends React.Component<TagFiltersProps, { activeTags: Array<TagType> }> {
    constructor(props: TagFiltersProps) {
        super(props);
        this.state = {
            activeTags: []
        }
    }

    render() {
        return <div id={'tagFilters'}>
            <TagFilter type={'tech'} toggleActive={this.toggleActive.bind(this)} active={this.isActive('tech')}/>
            <TagFilter type={'project'} toggleActive={this.toggleActive.bind(this)} active={this.isActive('project')}/>
            <TagFilter type={'blog'} toggleActive={this.toggleActive.bind(this)} active={this.isActive('blog')}/>
        </div>
    }

    isActive(tag: TagType) {
       return this.state.activeTags.indexOf(tag)!==-1;
    }

    toggleActive(tag: TagType) {
        const tags = this.state.activeTags.slice();
        // tag not here yet? make it active
        if (tags.indexOf(tag) === -1) {
            tags.push(tag);
        }
        // tag already there? remove it
        else {
            tags.splice(tags.indexOf(tag), 1);
        }
        // set new state
        this.setState({
            ...this.state,
            activeTags: tags
        })
        // update listeners
        this.props.activeTagsChanged(tags);
    }
}

export default TagFilters;
