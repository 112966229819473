import ReactDOM from "react-dom";
import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import './i18n';
import LocalizedApp from "./component/localizedApp";

export type Language = 'nl' | 'en';

ReactDOM.render(
    <Router>
        <LocalizedApp/>
    </Router>,
    document.getElementById('root')
);

