import React from "react";
import BlogItem from "./blogItem";
import TagFilters from "./tagFilters";
import {TagType} from "./blogTag";
import {Language} from "../index";

interface BlogState {
    posts: Array<{
        id: string,
        info: {
            title: {
                nl: string,
                en: string
            },
            tags: Array<any>,
            created_timestamp: number
        }
    }>,
    activeTags: Array<TagType>
}

interface BlogProps {
    language: Language
}

class Blog extends React.Component<BlogProps, BlogState> {
    constructor(props: any) {
        super(props);
        this.state = {posts: [], activeTags: []};
    }

    componentDidMount() {
        fetch(".././assets/posts.json")
            .then(result => result.json())
            .then(posts => this.setState({posts: posts}))
    }

    render() {
        const blogItems = this.state.posts
            // sort from new to old
            .sort((a, b) => (b.info.created_timestamp - a.info.created_timestamp))
            // filter according to active tags
            .filter(post => {
                // if no filters are active, allow everything
                if (this.state.activeTags.length === 0) {
                    return true;
                } else {
                    // active filter, only allow the post if the tag type matches
                    const intersection = post.info.tags.filter(v => this.state.activeTags.includes(v));
                    return intersection.length > 0;
                }
            })
            // map each post to a JSX element
            .map(post => {
                    return <BlogItem
                        key={post.id}
                        id={post.id}
                        title={post.info.title[this.props.language]}
                        tags={post.info.tags}
                        language={this.props.language}
                        date={new Date(post.info.created_timestamp * 1000)}
                    />
                }
            )
        return (
            <div id="blog">
                <TagFilters activeTagsChanged={(tags) => {
                    this.setState({
                        ...this.state,
                        activeTags: tags
                    })
                }}/>
                {blogItems}
            </div>
        );
    }


}

export default Blog;
