import {Route} from "react-router-dom";
import PageType from "./pageType";
import React from "react";
import Name from "./name";
import Buttons from "./buttons";
import Page from "./page";
import {Language} from "../index";

export class App extends React.Component<{language: Language}, { mousingButton: string | null }> {
    constructor(props: any) {
        super(props);
        this.state = {
            mousingButton: null
        }
    }

    render() {
        return (
            <div id={'app'} className={'container'}>
                <Name active={this.state.mousingButton === null}/>
                <Buttons
                    language={this.props.language}
                    mousing={(title) => {
                    // console.log('buttons are moving: ' + title);
                    if (this.state.mousingButton !== title) {
                        this.setState({mousingButton: title})
                    }
                }}/>
                <Route>
                    <PageType/>
                </Route>
                <Page language={this.props.language}/>
            </div>
        );
    }
}
