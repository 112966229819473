import React from "react";
import {
    faMicrochip, faWrench, faMicrophone, faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SizeProp} from "@fortawesome/fontawesome-svg-core";

function BlogTag(props: { type: TagType }) {
    let size: SizeProp = 'sm';
    let icon;
    if (props.type === 'tech') {
        icon = <FontAwesomeIcon size={size} icon={faMicrochip}/>
    } else if (props.type === 'project') {
        icon = <FontAwesomeIcon size={size} icon={faWrench}/>
    } else if (props.type === 'blog') {
        icon = <FontAwesomeIcon size={size} icon={faMicrophone}/>
    } else {
        icon = <FontAwesomeIcon size={size} icon={faQuestionCircle}/>
    }

    return icon;
}

export type TagType = 'tech' | 'project' | 'blog';

export default BlogTag;
